import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Navigation from "sections/Navigation"
import BannerClassic from "sections/Banner-classic"
import FeaturesClassic from "sections/Features-classic"
import Footer from "sections/Footer-classic"

import { GlobalStyle } from "sections/app.style"
import theme from "theme/classic/themeStyles"

import AppScreenImage from "assets/images/app/app-screen/water.png";

import Icon1 from "assets/images/app/app-feature/1.svg";
import Icon2 from "assets/images/app/app-feature/2.svg";
import Icon3 from "assets/images/app/app-feature/6.svg";
import Icon4 from "assets/images/app/app-feature/5.svg";
import Icon5 from "assets/images/app/app-feature/4.svg";
import Icon6 from "assets/images/app/app-feature/7.svg";

class FeatureInfo {
  constructor(title, detail, icon) {
    this.title = title;
    this.detail = detail;
    this.icon = icon;
  }
}

const DrinkReminderPage = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO title="Appion Gatsby Home" />
      <Navigation pageName="Water" />
      <BannerClassic 
        title="Stay healthy" appName="Drink Reminder" 
        detail="Drink water on time and live a healthy life"
        appScreenImage={AppScreenImage}
        link_web = "/drinkReminder#appFeatures"
        link_apple = "https://apps.apple.com/us/app/drink-reminder-water-tracker/id1550896263"
      />
      <div id="appFeatures">
        <FeaturesClassic 
          appName="Drink Reminder"
          itemGroup={[
              new FeatureInfo("Cloud syncing", "Automatically sync with iCloud",Icon1),
              new FeatureInfo("Statistics", "Control your water intake", Icon2),
              new FeatureInfo("Calendar", "See water intake history", Icon3),
              new FeatureInfo("Settings", "Get smarter notifications", Icon4),
              new FeatureInfo("Surprise !", "Let’s see what’s behind the UI", Icon5),
              new FeatureInfo("Healthy tips", "Make you living healthier", Icon6)
            ]} 
        />
      </div>
      <Footer privacyLink="/Privacy/privacy_en" termsLink="/Terms/terms_en" />
    </Layout>
  </ThemeProvider>
)
export default DrinkReminderPage